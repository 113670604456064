import { isNumber } from '@chakra-ui/utils';
import { SalaryDistributionProps } from './types';

export const getWeightedAverageSalariesAndCDFs = (
  comps: SalaryDistributionProps['competitors'],
  data: NonNullable<SalaryDistributionProps['salaryData']>['value'] | undefined
) => {
  if (!data?.length || !comps) {
    return { aggregatedSalaryValues: [], aggregatedCumulativePercentage: [] };
  }

  const aggregatedSalaryValues = data?.[0]?.value?.map((_, index) => {
    const { totalWeightedValue, totalRelevantWeight } = comps.reduce(
      (acc, comp) => {
        const dataItem = data.find(
          (d) => d.metadata.longName === (comp?.metadata?.id || '')
        );

        if (dataItem && isNumber(dataItem?.value?.[index])) {
          const score = comp?.closeness_score || 0;
          return {
            totalWeightedValue:
              acc.totalWeightedValue + dataItem.value[index] * score,
            totalRelevantWeight: acc.totalRelevantWeight + score,
          };
        }

        return acc;
      },
      { totalWeightedValue: 0, totalRelevantWeight: 0 }
    );

    return totalRelevantWeight > 0
      ? totalWeightedValue / totalRelevantWeight
      : 0;
  });

  const aggregatedCumulativePercentage = data?.[0]?.metadata?.cdf?.map(
    (_, index) => {
      const { totalWeightedCDF, totalRelevantWeight } = comps.reduce(
        (acc, comp) => {
          const dataItem = data?.find(
            (d) => d?.metadata?.longName === (comp?.metadata?.id || '')
          );

          if (dataItem && isNumber(dataItem?.metadata?.cdf?.[index])) {
            const score = comp?.closeness_score || 0;

            return {
              totalWeightedCDF:
                acc.totalWeightedCDF + dataItem.metadata.cdf[index] * score,
              totalRelevantWeight: acc.totalRelevantWeight + score,
            };
          }

          return acc;
        },
        { totalWeightedCDF: 0, totalRelevantWeight: 0 }
      );

      return totalRelevantWeight > 0
        ? totalWeightedCDF / totalRelevantWeight
        : 0;
    }
  );

  return {
    aggregatedSalaryValues,
    aggregatedCumulativePercentage,
  };
};
