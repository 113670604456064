import { colors, D3ChartNames, ID3ChartProps, KDEPlot } from '@revelio/d3';
import { getWeightedAverageSalariesAndCDFs } from './getWeightedAverageSalariesAndCDFs';
import { SalaryDistributionProps } from './types';
import { useContext, useEffect, useMemo } from 'react';
import { PlotLayoutCard } from '../../../../shared/components/plots/plot-layout-card';
import { PlotActionMenu } from '../../../../shared/components/plots/plot-action-menu';
import { Text } from '@chakra-ui/react';
import { ChartsRenderedContext } from '../../shared/charts-rendered-context';

export const SalaryDistribution = ({
  salaryData,
  competitors,
  primaryCompanyRCID,
  primaryCompanyName,
  setLoading,
}: SalaryDistributionProps) => {
  const { aggregatedSalaryValues, aggregatedCumulativePercentage } = useMemo(
    () =>
      getWeightedAverageSalariesAndCDFs(
        competitors,
        salaryData?.value?.filter(
          (comp) => comp?.metadata?.longName !== primaryCompanyRCID
        )
      ),
    [competitors, salaryData, primaryCompanyRCID]
  );

  useEffect(() => {
    if (salaryData && competitors?.length === 0) {
      setLoading(false);
    } else if (
      !aggregatedSalaryValues?.length &&
      !aggregatedCumulativePercentage?.length &&
      (!competitors || competitors?.length === 0)
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    aggregatedCumulativePercentage,
    aggregatedSalaryValues,
    setLoading,
    salaryData,
    competitors,
  ]);

  const primaryCompanyData = salaryData?.value?.find(
    (comp) => comp?.metadata?.longName === primaryCompanyRCID
  );

  const weightedCompetitors = {
    value: aggregatedSalaryValues,
    metadata: {
      salaries: primaryCompanyData?.metadata?.salaries,
      cdf: aggregatedCumulativePercentage,
    },
  };

  const data = [
    {
      ...primaryCompanyData,
      metadata: {
        ...primaryCompanyData?.metadata,
        longName: primaryCompanyName,
      },
    },
    {
      ...weightedCompetitors,
      metadata: {
        ...weightedCompetitors?.metadata,
        longName: 'Competitors',
      },
    },
  ];

  const title = 'Salary Distribution';
  const primaryCompanyColor = '#5BD992';
  const competitorColor = colors[0];
  const chartProps = {
    data,
    ttMainFormat: '$,',
    ttType: 'single',
    lineColor: [primaryCompanyColor, competitorColor],
    gradient: [`${primaryCompanyColor}35`, `${competitorColor}35`],
    xAxisLabelSize: '12px',
  };

  const context = useContext(ChartsRenderedContext);
  if (!context) {
    throw new Error(
      'ChartComponent must be used within a ChartsRenderedProvider'
    );
  }
  const { setChartHasRendered } = context;

  return (
    <PlotLayoutCard
      title={title}
      plotInfo={{
        plotInfoBody: (
          <Text color="white">
            The distribution of total compensation. More information on the
            compensation model can be found{' '}
            <Text
              as="span"
              color="green.300"
              _hover={{ textDecoration: 'underline' }}
            >
              <a
                href="https://www.data-dictionary.reveliolabs.com/methodology.html#salary"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </Text>
            .
          </Text>
        ),
        plotInfoConfig: {
          popoverPlacement: 'right',
          iconBoxSize: 2.5,
        },
      }}
      menu={
        data?.length > 0 && (
          <PlotActionMenu
            title={title}
            chartType={D3ChartNames.KDE}
            chartProps={chartProps as ID3ChartProps}
            expandedPlot={
              <KDEPlot {...chartProps} name={`Expanded ${title}`} />
            }
            pr={2}
          />
        )
      }
    >
      {data?.length > 0 && (
        <KDEPlot
          {...chartProps}
          name={COMPANY_SUMMARY_SALARY_DISTRIBUTION}
          setChartHasRendered={setChartHasRendered}
        />
      )}
    </PlotLayoutCard>
  );
};

export const COMPANY_SUMMARY_SALARY_DISTRIBUTION =
  'company_summary_salary_distribution';
