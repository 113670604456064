import { selectAllEntities } from '@ngneat/elf-entities';
import { useEffect$ } from '@ngneat/react-rxjs';
import {
  // DASHBOARD_ERRORS,
  Views,
  //  _DASHBOARD_ERROR_STATUS,
} from '@revelio/core';
import {
  capitalize,
  compact,
  defer,
  difference,
  find,
  get,
  has,
  includes,
  isArray,
  isBoolean,
  isEmpty,
  isEqual,
  isFinite,
  isUndefined,
  mapKeys,
  mapValues,
  omit,
  pick,
  reduce,
  set,
  without,
} from 'lodash';
import { parse, stringify } from 'query-string';
import { useNavigate } from 'react-router-dom';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  skipUntil,
  tap,
} from 'rxjs';
import { ViewsForDefaultsOnly, ViewTypes } from '../data-api/data-api.model';

import {
  filterLabelLookup,
  FilterStateDefaultsMap,
  FiltersUsedInSelectSubfilters,
  FiltersUsedInTabs,
  PYTHON_COMPANY_SELECTION_ID,
  ROLE_GRANULARITY_FILTERS,
  SkillsFilterStateDefaultMap,
} from './filters.constants';
import {
  filterStore,
  globalPageLoadingStatus,
  upsertFiltersWithProvidedValue,
} from './filters.engine';
import {
  Filter,
  FilterItem,
  FilterList,
  FilterName,
  FilterOrSubfilterName,
  FilterTypes,
  LocalSelectionCategories,
  OtherFilterNames,
  RangeFilter,
  SelectFilter,
  SelectableCategories,
  SelectionCategories,
  SelectionList,
  SelectionListIdNames,
  SubFilterNames,
  ValidValueTypes,
} from './filters.model';
import {
  ALL_DATE_FILTER_IDS,
  ALL_SUPPORTED_FILTERS,
  DEEP_LINK_SUPPORTED_VIEWS,
  SUPPORTED_DEEP_LINK_FILTERS_BY_PAGE,
} from './filters.deepLinks.model';
import { selectionListDataSource } from './filters.repository';
import { buildFilters } from './filters.serialize';
import { JsonValue } from 'type-fest';
import { AUTH_CHECKED, authStore } from '@revelio/auth';
import { select } from '@ngneat/elf';
import { TextFilters } from '../filter-components/filter-menu/keyword-filters/keyword-filters';
import {
  CustomRoleTaxonomySelection,
  POSTING_SOURCE_IDS,
} from '@revelio/data-access';
import { getPostingsSourceOptions } from '../filter-components/filter-menu/tree-filters/posting-source-options';

type FilterCategories = SelectionCategories | LocalSelectionCategories;

interface UseSyncFiltersToSearchParamsProps {
  primaryFilters: FilterCategories[];
  syncToPrimaryEntities?: boolean;
}

export const getProviderDefault = (
  userHasWebsitePostings: boolean,
  unifiedPostingsEnabled: boolean
) => {
  return {
    [LocalSelectionCategories.PROVIDER]: {
      isMulti: true,
      value: [
        (() => {
          if (userHasWebsitePostings) {
            return {
              id: 2,
              label: 'Website Postings (other)',
              shortName: 'Website Postings (other)',
            };
          }

          if (unifiedPostingsEnabled) {
            return {
              id: POSTING_SOURCE_IDS.unified,
              label: 'Unified (COSMOS)',
              shortName: 'Unified (COSMOS)',
            };
          }

          return {
            id: 3,
            label: 'LinkedIn',
            shortName: 'LinkedIn',
            parentId: -1,
          };
        })(),
      ],
      selectionListId: LocalSelectionCategories.PROVIDER,
    },
  };
};

const authenticatedUser$ = authStore.pipe(
  skipUntil(AUTH_CHECKED),
  select((state) => state.user),
  distinctUntilChanged((prev, cur) => {
    return (
      prev?.linkup_postings === cur?.linkup_postings ||
      prev?.unified_postings === cur?.unified_postings
    );
  })
);

/** When filters update, serialize filter state into string and insert into url */
export const useSyncFiltersToSearchParams = ({
  primaryFilters,
  syncToPrimaryEntities = false,
}: UseSyncFiltersToSearchParamsProps) => {
  const navigate = useNavigate();

  useEffect$(() => {
    const loctionState = { pathname: window.location.pathname };
    return combineLatest({
      globalPageLoadingStatus,
      filters: filterStore.pipe(selectAllEntities()),
    }).pipe(
      filter(({ globalPageLoadingStatus: { areWeLoading } }) => {
        // when navigating away from a page globalPageLoadingStatus changes once and causes stale url state within
        // setSearchParams to navigate back wrongly
        if (loctionState.pathname != window.location.pathname) {
          loctionState.pathname = window.location.pathname;
          return false;
        }
        // only set filter search params once page is loaded so it doesn't overwrite them as filter store is being populated
        return areWeLoading === false;
      }),
      map(({ filters }) => filters),
      distinctUntilChanged(),
      // only set filters if the required filters are set to not have broken filter states (e.g. no primary filter)
      filter((filters) =>
        // TODO: jbellizzi - look for better way to compare id type to required filter
        {
          return !!filters.find((f) =>
            primaryFilters.some(
              (requiredFilter) =>
                requiredFilter === f.id ||
                requiredFilter === get(f, 'selectionListId')
            )
          );
        }
      ),
      tap((allFilters: Filter[]) => {
        const primaryFilterValues = {};

        const filtersToAddToUrl = allFilters.filter((item) => {
          if (syncToPrimaryEntities) {
            if (
              primaryFilters.includes(
                item.id as LocalSelectionCategories | SelectionCategories
              )
            ) {
              set(primaryFilterValues, item.id, item);
              return false;
            }

            if (
              primaryFilters.includes(
                get(item, 'selectionListId') as
                  | LocalSelectionCategories
                  | SelectionCategories
              )
            ) {
              set(primaryFilterValues, get(item, 'selectionListId'), item);
              return false;
            }
          }

          /** if filter name in default set and filter value is not default value, allow filter */
          if (item.id in FilterStateDefaultsMap) {
            return !isEqual(item.value, FilterStateDefaultsMap[item.id]?.value);
          }

          /** if filter name in default skills set and filter value is not default skills value, allow filter */
          if (item.id in SkillsFilterStateDefaultMap) {
            return !isEqual(
              item.value,
              SkillsFilterStateDefaultMap[item.id]?.value
            );
          }

          if (item.id === OtherFilterNames.ROLE_TAXONOMY) {
            // we only care about saving role taxonomy in deep link instead of calculating
            // when role from specific taxonomy set
            // and the primary filters might have different adaptive taxonomy
            const hasRoleFilterSet = (
              allFilters as SelectFilter<FilterList>[]
            ).filter((item) =>
              includes(ROLE_GRANULARITY_FILTERS, item.id)
            ).length;
            return hasRoleFilterSet;
          }

          /** if item is not primary_filter or grouped, allow filter */
          return ![
            SelectionCategories.PRIMARY_FILTER,
            OtherFilterNames.GROUPED,
          ].some((filterName) => filterName === item.id);
        });

        const serializedFilters = filtersToAddToUrl.reduce(
          (acc, filterEntity) => {
            const filterId = filterEntity.id;
            if (
              includes(
                [
                  SelectionCategories.DATE_RANGE,
                  SelectionCategories.DATE_RANGE_FULL,
                ],
                filterId
              )
            ) {
              if ((filterEntity as RangeFilter).isMaximumRange) {
                // Only add the date range if it's an explicitly set filter rather than just the maximum default
                return acc;
              }

              const value = (filterEntity as RangeFilter).value;
              return {
                ...acc,
                ...(filterId === SelectionCategories.DATE_RANGE_FULL
                  ? mapKeys(value, (_, key) => `${key}Full`) // necessary so that there aren't clashing url search params since both Date range and Date range full have the same key in the filter value
                  : value),
              };
            }

            if (filterId === OtherFilterNames.ROLE_TAXONOMY) {
              const roleTaxonomyValue =
                filterEntity.value as CustomRoleTaxonomySelection;
              const roleTaxonomyType = Object.keys(
                roleTaxonomyValue
              )[0] as keyof CustomRoleTaxonomySelection;
              return {
                ...acc,
                [filterId]: `${roleTaxonomyType}__${roleTaxonomyValue[roleTaxonomyType]}`,
              };
            }

            if (
              syncToPrimaryEntities &&
              filterId == LocalSelectionCategories.PRIMARY_ENTITIES &&
              isArray(filterEntity.value)
            ) {
              return {
                ...acc,
                [filterEntity.id]: filterEntity.value.map((item: any) => {
                  const activeEntity = find(
                    Object.values(primaryFilterValues),
                    (ent: any) => {
                      return ent.selectionListId == item.selectionListId;
                    }
                  );

                  const activeEntityValues = get(activeEntity, 'value', []);

                  const foundActiveEntityItem = find(
                    activeEntityValues,
                    (val: any) => {
                      return val.id == item.id;
                    }
                  );

                  const isActive = !!foundActiveEntityItem;

                  return `${item.selectionListId}__${item.id}__${isActive}`;
                }),
              };
            }

            if (
              Object.values(LocalSelectionCategories).includes(
                filterEntity.id as LocalSelectionCategories
              ) &&
              (filterEntity.id as LocalSelectionCategories) !=
                LocalSelectionCategories.PROVIDER &&
              !isBoolean(filterEntity.value) // IS_UNIVERSITY needs to use the value like other filters
            ) {
              return {
                ...acc,
                [filterEntity.id]: (filterEntity.value as FilterItem).id,
              };
            }

            // for multi filters
            if (isArray(filterEntity.value)) {
              return {
                ...acc,
                [filterEntity.id]: filterEntity.value.map((item) => {
                  if (
                    TextFilters.includes(filterEntity.id as SelectionCategories)
                  ) {
                    return (item as FilterItem)?.value;
                  }
                  return (item as FilterItem)?.id;
                }),
              };
            }

            return {
              ...acc,
              [filterEntity.id]: filterEntity.value,
            };
          },
          {}
        );

        const newFilterDeepLink = stringify(serializedFilters, {
          arrayFormat: 'comma',
        });

        if (
          newFilterDeepLink !==
          decodeURIComponent(
            new URLSearchParams(window.location.search).toString()
          )
        ) {
          navigate(window.location.pathname + '?' + newFilterDeepLink);
        }
      })
    );
  }, []);
};

export const getPrimaryEntityMapping = (
  deepLinkFilters: SearchParamFilters,
  limit = 6
) => {
  const primaryEntities = get(
    deepLinkFilters,
    LocalSelectionCategories.PRIMARY_ENTITIES,
    []
  );

  const primaryEntitiesArray = Array.isArray(primaryEntities)
    ? primaryEntities
    : [primaryEntities];

  const selectionLists = new Set();

  const transformedPrimaryEntitiesArray = primaryEntitiesArray.map(
    (entityId) => {
      const [listId, id, isActive] = entityId.split('__');

      selectionLists.add(listId);

      // isActive is a string, compare to convert to boolean
      if (limit <= 0 && isActive == 'true') {
        return [listId, id, false].join('__');
      }

      if (isActive == 'true') {
        limit -= 1;
      }

      return entityId;
    }
  );

  const primaryEntityMapping = transformedPrimaryEntitiesArray?.reduce(
    (allEntities: any, entityId: string) => {
      const [listId, id, isActive] = entityId.split('__');

      // isActive is a string, compare to convert to boolean
      if (isActive == 'false') {
        return allEntities;
      }

      if (has(allEntities, listId)) {
        allEntities[listId].push(id);
      } else {
        allEntities[listId] = [id];
      }

      return allEntities;
    },
    {}
  );

  return [primaryEntityMapping, primaryEntitiesArray, selectionLists];
};

export const buildPrimaryEntities = (
  primaryEntities: string[],
  selectionLists: SelectionList<ValidValueTypes>[]
) => {
  const primaryEntityValues = primaryEntities.reduce(
    (allEntities: any, entityId: string) => {
      // default isActive value to true if deeplink missing active boolean
      const [selectionListId, id, isActive = 'true'] = entityId.split('__');

      const relevantList = selectionLists.find((list) => {
        return list.id == selectionListId;
      });

      const foundItem = relevantList?.value.find((val) => {
        return val.id == id;
      });

      if (foundItem) {
        return [
          ...allEntities,
          { ...foundItem, selectionListId, isActive: isActive === 'true' },
        ];
      }

      return allEntities;
    },
    []
  );

  return {
    id: LocalSelectionCategories.PRIMARY_ENTITIES,
    isMulti: true,
    type: FilterTypes.SELECT,
    value: primaryEntityValues,
  };
};
export type SearchParamFilters = {
  [x in SelectionListIdNames]: string | string[];
};
export const setSearchParamFilters = ({
  searchParamFilters,
  view,
  viewType,
  limits,
  includeDisabledFilters = false,
  primaryFilters,
  supportPrimaryEntities,
}: {
  searchParamFilters: URLSearchParams | undefined;
  view: Views | ViewsForDefaultsOnly;
  viewType?: ViewTypes;
  limits?: {
    [key in SelectableCategories]?: number;
  };
  includeDisabledFilters?: boolean;
  primaryFilters?: (SelectionListIdNames | OtherFilterNames)[];
  supportPrimaryEntities?: boolean;
}) => {
  if (!searchParamFilters?.toString()) {
    // use api defaults if no search params.
    return null;
  }

  const deepLinkFilters = parse(searchParamFilters?.toString(), {
    arrayFormat: 'comma',
  }) as SearchParamFilters;
  const supportedFiltersForPage =
    [
      ...(includeDisabledFilters
        ? ALL_SUPPORTED_FILTERS
        : SUPPORTED_DEEP_LINK_FILTERS_BY_PAGE[
            view as DEEP_LINK_SUPPORTED_VIEWS
          ][viewType as ViewTypes]),
      ...ALL_DATE_FILTER_IDS, // always included so the date filters persist when navigating between pages doesn't lose the date filters
    ] || [];

  const supportedDeepLinkFilters = pick(
    deepLinkFilters,
    supportedFiltersForPage
  );

  if (supportPrimaryEntities) {
    const primaryEntitiesDefinedInDeeplink = get(
      deepLinkFilters,
      LocalSelectionCategories.PRIMARY_ENTITIES
    );

    const isPrimaryEntitiesDefined = !isUndefined(
      primaryEntitiesDefinedInDeeplink
    );

    // if page supports filter consistency, but primary entities are not
    // defined in the deeplink, try to create them with the primary filter
    // values that are defined

    if (!isPrimaryEntitiesDefined) {
      const formattedPrimaryEntities: any = [];

      const primaryFiltersDefinedInDeeplink = pick(
        supportedDeepLinkFilters,
        primaryFilters
      );

      Object.entries(primaryFiltersDefinedInDeeplink).forEach(
        ([key, value]: [string, string | string[]]) => {
          const isValidSelectionListId = primaryFilters?.includes(
            key as SelectionListIdNames | OtherFilterNames
          );

          const entityIds = Array.isArray(value) ? value : [value];

          entityIds.forEach((id) => {
            const isValidId = isFinite(Number(id));

            if (!isValidSelectionListId || !isValidId) return;

            formattedPrimaryEntities.push(`${key}__${id}__${true}`);
          });
        }
      );

      // no valid fallbacks defined for primary entities, revert to defaults
      if (formattedPrimaryEntities.length == 0) {
        // _DASHBOARD_ERROR_STATUS.next(DASHBOARD_ERRORS.INVALID_DEEPLINK);
        return false;
      }

      set(
        deepLinkFilters,
        LocalSelectionCategories.PRIMARY_ENTITIES,
        formattedPrimaryEntities
      );
    }
  }

  const [
    primaryEntityMapping,
    primaryEntitiesArray,
    primaryEntitySelectionLists,
  ] = getPrimaryEntityMapping(
    deepLinkFilters,
    get(limits, LocalSelectionCategories.PRIMARY_ENTITIES, 6)
  );

  const allDeepLinkFilters = {
    ...supportedDeepLinkFilters,
    ...primaryEntityMapping,
  };

  const filtersWithoutSelectionList = without(
    [...Object.values(LocalSelectionCategories), ...TextFilters],
    ...FiltersUsedInTabs,
    LocalSelectionCategories.PRIMARY_ENTITIES,
    LocalSelectionCategories.PROVIDER
  );

  const deepLinkFiltersWithSelectionList = omit(allDeepLinkFilters, [
    ...filtersWithoutSelectionList,
    ...ALL_DATE_FILTER_IDS,
  ]);

  // this is so we can add the selectionListId for subfilters where the name of the filter is different to the selection list name
  const deepLinkFiltersWithSelectionListIds =
    getFilterValuesWithSelectionListId(deepLinkFiltersWithSelectionList);
  const requiredSelectionListIds = Object.values(
    deepLinkFiltersWithSelectionListIds
  ).reduce<string[]>((acc, filter) => {
    if (
      filter.selectionListId &&
      filter.selectionListId !== LocalSelectionCategories.PROVIDER
    ) {
      return [...acc, filter.selectionListId];
    }
    return acc;
  }, []);

  return combineLatest([
    of(deepLinkFiltersWithSelectionListIds),
    of(primaryEntitiesArray),
    selectionListDataSource.data$({
      key: [
        ...requiredSelectionListIds,
        ...Array.from(primaryEntitySelectionLists),
      ],
    }),
    authenticatedUser$,
  ]).pipe(
    // we need the selection lists to be loaded to find the filter values by id from the search params
    filter(([searchParamFilters, , { selectionLists }, authenticatedUser]) => {
      const hasRequiredSelectionLists =
        difference(
          requiredSelectionListIds.filter(
            (id) => id !== LocalSelectionCategories.PROVIDER
          ),
          selectionLists.map((list) => list.id)
        ).length === 0;

      return (
        !isUndefined(authenticatedUser) &&
        !isEmpty(searchParamFilters) &&
        hasRequiredSelectionLists
      );
    }),
    distinctUntilChanged(),
    tap(
      ([
        searchParamFilters,
        primaryEntities,
        { selectionLists },
        authenticatedUser,
      ]) => {
        const deepLinkFilterDates = pick(
          supportedDeepLinkFilters,
          ALL_DATE_FILTER_IDS
        );

        const userHasLinkupPostings = !!authenticatedUser?.linkup_postings;
        const unifiedPostingsEnabled = !!authenticatedUser?.unified_postings;

        const providerDefault = getProviderDefault(
          userHasLinkupPostings,
          unifiedPostingsEnabled
        );

        const SubFilterStateDefaults =
          view === Views.SKILL
            ? SkillsFilterStateDefaultMap
            : {
                ...FilterStateDefaultsMap,
                ...providerDefault,
              };

        const postingsOptions = authenticatedUser
          ? getPostingsSourceOptions(authenticatedUser).map((option) => {
              return { id: Number(option.value), ...option };
            })
          : [];

        const filtersToBuild = {
          ...getSearchParamFilterValueFromSelectionList(
            searchParamFilters,
            selectionLists,
            postingsOptions
          ),
          ...deepLinkFilterDates,
        };

        const hasCompanyFilters =
          get(filtersToBuild, SelectionCategories.COMPANY) ||
          get(filtersToBuild, PYTHON_COMPANY_SELECTION_ID);

        const companyFiltersLookupSuccessfull =
          compact(hasCompanyFilters || []).length > 0;
        if (hasCompanyFilters && !companyFiltersLookupSuccessfull) {
          window.location.search = '';
          return;
        }

        const toInsertFiltersArray = buildFilters(
          filtersToBuild,
          // this map builds the sub filters with the correct selectionListId which checks the correct values in the plot sub filter tree
          {
            filterSelectionListMap: mapValues(
              SubFilterStateDefaults,
              'selectionListId'
            ) as JsonValue,
          },
          view
        );

        if (!isEmpty(primaryEntities)) {
          const builtPrimaryEntities = buildPrimaryEntities(
            primaryEntities,
            selectionLists
          );

          toInsertFiltersArray.push(builtPrimaryEntities as Filter);
        }

        const roleTaxonomyDeepLink = get(
          deepLinkFilters,
          OtherFilterNames.ROLE_TAXONOMY,
          false
        );
        if (roleTaxonomyDeepLink) {
          const [roleTaxonomyType, roleTaxonomyId] =
            roleTaxonomyDeepLink.split('__');
          toInsertFiltersArray.push({
            id: OtherFilterNames.ROLE_TAXONOMY,
            label: OtherFilterNames.ROLE_TAXONOMY,
            type: FilterTypes.SELECT,
            value: {
              [roleTaxonomyType]:
                roleTaxonomyType === 'default' ? true : roleTaxonomyId,
            },
          } as Filter);
        }

        const toInsertObject = toInsertFiltersArray.reduce(
          (object: { [key in FilterName]?: Partial<Filter> }, filter) => {
            object[filter.id] = filter;
            if (filter.id === LocalSelectionCategories.PROVIDER) {
              (object[filter.id] as SelectFilter<FilterItem>).isUserSubmitted =
                true; // without this, it won't apply the the provider filter
            }
            if (filter.id === LocalSelectionCategories.N_ITEMS_SANKEY) {
              (object[filter.id] as SelectFilter<FilterItem>).isMulti = false;
            }
            return object;
          },
          {
            // set all the plot sub filters depending on the view's defaults
            ...SubFilterStateDefaults,
          }
        );

        const deepLinkFiltersWithoutSelectionList = pick(
          supportedDeepLinkFilters,
          filtersWithoutSelectionList
        );

        defer(() => {
          const localfilters = getLocalSelectionCategoryFilters(
            deepLinkFiltersWithoutSelectionList as {
              [x in LocalSelectionCategories]: string;
            }
          );

          const textFilters = getTextFilters(
            pick(deepLinkFiltersWithoutSelectionList, TextFilters)
          );

          upsertFiltersWithProvidedValue({
            ...toInsertObject,
            ...localfilters,
            ...textFilters,
          });
        });
      }
    )
  );
};

const getFilterValuesWithSelectionListId = (
  deepLinkFiltersWithSelectionList: Partial<SearchParamFilters>
): {
  [x: string]: {
    value: string | string[];
    selectionListId: SelectionListIdNames | undefined;
  };
} => {
  const getMultiGranularitySelectionListId = (
    queryParamFilterId: FilterOrSubfilterName
  ) => {
    if (
      queryParamFilterId ===
      (PYTHON_COMPANY_SELECTION_ID as SelectionCategories)
    ) {
      return SelectionCategories.COMPANY;
    }

    if (queryParamFilterId === SubFilterNames.SUB_SKILL_OVERTIME) {
      if (deepLinkFiltersWithSelectionList[SelectionCategories.SKILL_K75]) {
        return SelectionCategories.SKILL_K700;
      }

      if (deepLinkFiltersWithSelectionList[SelectionCategories.SKILL_K700]) {
        return SelectionCategories.SKILL_K3000;
      }
    }

    if (queryParamFilterId === SubFilterNames.SUB_ROLE) {
      if (deepLinkFiltersWithSelectionList[SelectionCategories.JOB_CATEGORY]) {
        return SelectionCategories.ROLE_K50;
      }

      if (deepLinkFiltersWithSelectionList[SelectionCategories.ROLE_K150]) {
        return SelectionCategories.ROLE_K500;
      }
    }

    if (queryParamFilterId === SubFilterNames.SUB_REGION) {
      if (deepLinkFiltersWithSelectionList[SelectionCategories.REGION]) {
        return SelectionCategories.COUNTRY;
      }

      if (deepLinkFiltersWithSelectionList[SelectionCategories.COUNTRY]) {
        return SelectionCategories.METRO_AREA;
      }
    }

    return FilterStateDefaultsMap[queryParamFilterId as FilterOrSubfilterName]
      ?.selectionListId;
  };

  return mapValues(
    deepLinkFiltersWithSelectionList,
    (value, queryParamFilterId) => {
      const searchParamValue = value as string | string[];
      if ((queryParamFilterId as string) in FilterStateDefaultsMap) {
        return {
          value: searchParamValue,
          selectionListId: getMultiGranularitySelectionListId(
            queryParamFilterId as FilterOrSubfilterName
          ),
        };
      }

      if ((queryParamFilterId as string) in SkillsFilterStateDefaultMap) {
        return {
          value: searchParamValue,
          selectionListId:
            SkillsFilterStateDefaultMap[
              queryParamFilterId as FilterOrSubfilterName
            ]?.selectionListId,
        };
      }

      return {
        value: searchParamValue,
        selectionListId: queryParamFilterId as SelectionListIdNames,
      };
    }
  );
};

const getSearchParamFilterValueFromSelectionList = (
  searchParamFilters: ReturnType<typeof getFilterValuesWithSelectionListId>,
  selectionLists: SelectionList<ValidValueTypes>[],
  postingsOptions: { id: number; value: string }[] | []
) => {
  return Object.keys(searchParamFilters).reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (filterWithValues: { [key: string]: any }, selectionListId: string) => {
      const filterSelectionList = selectionLists.find(
        (selectionList) =>
          selectionList.id ===
          searchParamFilters[selectionListId].selectionListId
      );

      const filterValuesToReduce = isArray(
        searchParamFilters[selectionListId].value
      )
        ? (searchParamFilters[selectionListId].value as string[])
        : [searchParamFilters[selectionListId].value as string];

      const filterValues = reduce(
        filterValuesToReduce,
        (acc: any, queryParamFilterid: any) => {
          if (selectionListId === LocalSelectionCategories.PROVIDER) {
            const providerSelectionListValue = postingsOptions.find(
              (option) => option.value === queryParamFilterid
            );

            if (providerSelectionListValue) {
              return [
                ...acc,
                {
                  ...providerSelectionListValue,
                  value: Number(providerSelectionListValue.value),
                },
              ];
            }

            return acc;
          }

          const foundSelectionListItem = filterSelectionList?.value.find(
            (selectionListValue) =>
              selectionListValue.id === Number(queryParamFilterid) ||
              selectionListValue.id === queryParamFilterid
          );

          if (foundSelectionListItem) {
            return [...acc, foundSelectionListItem];
          }

          return acc;
        },
        []
      );

      const isArrayValue = [
        ...(FiltersUsedInTabs as string[]),
        ...(FiltersUsedInSelectSubfilters as string[]),
        LocalSelectionCategories.DATA_METRIC,
      ].includes(selectionListId);

      if (
        selectionListId === LocalSelectionCategories.PROVIDER &&
        filterValues.length === 0
      ) {
        return filterWithValues;
      }

      return {
        ...filterWithValues,
        [selectionListId]: isArrayValue ? filterValues[0] : filterValues,
      };
    },
    {}
  );
};

const getTextFilters = (deepLinkTextFilters: {
  [key in SelectionCategories]: string[];
}) => {
  return (Object.keys(deepLinkTextFilters) as SelectionCategories[])?.reduce<{
    [key in SelectionCategories]?: SelectFilter<any>;
  }>((toInsertObject, filterId) => {
    const filterValue = deepLinkTextFilters[filterId];
    const valuesArray = isArray(filterValue) ? filterValue : [filterValue];

    toInsertObject[filterId] = {
      id: filterId,
      label: filterLabelLookup[filterId] || filterId,
      isMulti: true,
      type: FilterTypes.SELECT,
      selectionListId: filterId,
      value: valuesArray.map((value: string) => {
        const cleanedValue = value.trim().toString();
        return {
          label: cleanedValue,
          value: cleanedValue,
          __isNew__: true,
        };
      }),
    };

    return toInsertObject;
  }, {});
};

const getLocalSelectionCategoryFilters = (deepLinkFiltersWithoutSelectionList: {
  [key in LocalSelectionCategories]: string;
}) => {
  return (
    Object.keys(
      deepLinkFiltersWithoutSelectionList
    ) as LocalSelectionCategories[]
  ).reduce<{
    [key in LocalSelectionCategories]?: SelectFilter<
      FilterItem<ValidValueTypes>
    >;
  }>((toInsertObject, filterId) => {
    toInsertObject[filterId] = {
      id: filterId,
      label: filterId,
      type: FilterTypes.SELECT,
      value: {
        id: deepLinkFiltersWithoutSelectionList[filterId],
        label: capitalize(deepLinkFiltersWithoutSelectionList[filterId]),
        shortName: deepLinkFiltersWithoutSelectionList[filterId],
        index: [ViewTypes.SNAPSHOT, 'inflow', ViewTypes.BASE_SALARY].includes(
          deepLinkFiltersWithoutSelectionList[filterId]
        )
          ? 0
          : 1,
      },
      isMulti: false,
    };

    if (filterId === LocalSelectionCategories.POSTING_METRIC) {
      (toInsertObject[filterId] as SelectFilter).value = {
        id: toInsertObject[filterId]?.value.id as string,
        label: toInsertObject[filterId]?.value.label,
        value: deepLinkFiltersWithoutSelectionList[filterId], // data is replaced by value
      };
    }

    if (filterId === LocalSelectionCategories.N_ITEMS_SANKEY) {
      (toInsertObject[filterId] as SelectFilter).value = {
        id: (toInsertObject[filterId] as SelectFilter<FilterItem>).value.id,
        label: deepLinkFiltersWithoutSelectionList[filterId], // not capitalised
        // don't need any other keys
      };
      (toInsertObject[filterId] as SelectFilter).selectionListId = filterId;
    }

    return toInsertObject;
  }, {});
};
